<template>
  <div class="container">
    <div class="transcript-for-group mt-4">
      <h4 class="text-center">Дисциплины группы</h4>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Курс</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyCourse">
            <option v-for="(sCourse, sCourseIndex) in studyCourses" :key="sCourseIndex" :value="sCourse.value">
              {{sCourse.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option v-for="(item, index) in docOrdersStudents_form.studyLevels" :value="item.id" :key="index">
              {{item.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="studyCourse&&studyLevelId">
        <label class="col-md-3 col-form-label">Образовательная программа</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="educationProgramId">
            <option v-for="(item, index) in docOrdersStudents_form.educationPrograms" :value="item.id" :key="index">
              {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
              {{ item.language_name }} - {{ item.admission_year }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="educationProgramId != 0">
        <div class="col-md-3">Группы</div>
        <div class="col-md-9" v-if="!loadingGroup">
          <div v-if="docOrdersStudents_form.studentGroups.length">
            <select class="form-control form-select" v-model="groupId">
              <option v-for="(item, index) in docOrdersStudents_form.studentGroups" :value="item.id" :key="index">
                {{item.name}}
              </option>
            </select>
          </div>
          <div v-else class="text-danger">
            В данной образовательной программе нет групп
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="groupId">

        <div v-if="loadingDisciplines" class="d-flex justify-content-center my-5">
          <Preloader/>
        </div>
        <div v-else>
          <!-- old disciplines -->
          <div class="my-3">
            <div class="my-3 text-center fs-5">
              Дисциплины OLDMOODLE
            </div>
            <div class="table-responsive mt-4">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th scope="col" style="width: 5%">#</th>
                  <th scope="col" style="width: 60%">Название дисциплины</th>
                  <th scope="col" style="width: 10%"><i class="pi pi-pencil"></i> Название</th>
                  <th scope="col" style="width: 15%">Кредит</th>
                  <th scope="col" style="width: 10%"><i class="pi pi-pencil"></i> Кредит</th>
                </tr>
                </thead>
                <tbody v-for="(disciplines,semesterKey) in oldDisciplines" :key="semesterKey">

                <template v-if="disciplines.length">
                  <tr class="sem_tr">
                    <td colspan="5">
                      {{semesterKey}} семестр
                    </td>
                  </tr>
                  <tr v-for="(discipline, disciplineIndex) in disciplines" :key="disciplineIndex">
                    <th scope="row">{{disciplineIndex + 1}}</th>
                    <td>
                      <div class="mb-2">
                        <input type="text" class="form-control" placeholder="Название на русском"
                               :disabled="!discipline?.name_edit_status"
                               :value="discipline.discipline_name_ru"
                               @input="discipline.discipline_name_ru = $event.target.value">
                      </div>
                      <div class="mb-2">
                        <input type="text" class="form-control" placeholder="Название на казахском"
                               :disabled="!discipline?.name_edit_status"
                               :value="discipline.discipline_name_kz"
                               @input="discipline.discipline_name_kz = $event.target.value">
                      </div>
                      <div>
                        <input type="text" class="form-control" placeholder="Название на английском"
                               :disabled="!discipline?.name_edit_status"
                               :value="discipline.discipline_name_en"
                               @input="discipline.discipline_name_en = $event.target.value">
                      </div>
                    </td>
                    <td>
                      <Button v-if="discipline?.name_edit_status" icon="pi pi-check" class="p-button-rounded mx-1"
                              :disabled="loadingDisciplinesUpdate"
                              @click="updateOldDisciplineName(discipline)"/>
                      <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning mx-1"
                              :disabled="loadingDisciplinesUpdate"
                              @click="discipline.name_edit_status = 1"/>
                    </td>
                    <td>
                      <input type="number" class="form-control" placeholder="Кредит"
                             :disabled="!discipline?.edit_status"
                             :value="discipline.credit"
                             @input="discipline.credit = $event.target.value">
                    </td>
                    <td>
                      <Button v-if="discipline?.edit_status" icon="pi pi-check" class="p-button-rounded mx-1"
                              :disabled="loadingDisciplinesUpdate"
                              @click="updateOldDiscipline(discipline)"/>
                      <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning mx-1"
                              :disabled="loadingDisciplinesUpdate"
                              @click="discipline.edit_status = 1"/>
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
          </div>
          <!-- end old disciplines -->


          <!-- front disciplines -->
          <div class="my-3">
            <div class="my-3 text-center fs-5">
              Дисциплины FRONT
            </div>
            <div class="table-responsive mt-4">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Название дисциплины</th>
                  <th scope="col">Кредит</th>
                  <th scope="col">Действие</th>
                </tr>
                </thead>
                <tbody v-for="(disciplines,semesterKey) in frontDisciplines" :key="semesterKey">

                <template v-if="disciplines.length">
                  <tr class="sem_tr">
                    <td colspan="4">
                      {{semesterKey}} семестр
                    </td>
                  </tr>
                  <tr v-for="(discipline, disciplineIndex) in disciplines" :key="disciplineIndex">
                    <th scope="row">{{disciplineIndex + 1}}</th>
                    <td>{{discipline?.discipline_name}}</td>
                    <td>
                      <input type="number" class="form-control" placeholder="Кредит"
                             :disabled="!discipline?.edit_status"
                             :value="discipline.credit"
                             @input="discipline.credit = $event.target.value">
                    </td>
                    <td>
                      <Button v-if="discipline?.edit_status" icon="pi pi-check" class="p-button-rounded mx-1"
                              :disabled="loadingDisciplinesUpdate"
                              @click="updateFrontDiscipline(discipline)"/>
                      <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning mx-1"
                              :disabled="loadingDisciplinesUpdate"
                              @click="discipline.edit_status = 1"/>
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
          </div>
          <!-- end front disciplines -->

        </div>


      </div>

    </div>
  </div>


</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"
  import httpClient from "@/services/http.service"

  export default {
    name: "ChangeDisciplineCreditAndName",
    data() {
      return {
        studyLevelId: 0,
        studyCourse: 0,
        educationProgramId: 0,
        groupId: 0,
        loadingGroup: false,
        studyCourses: [
          {
            value: 1,
            name: '1'
          },
          {
            value: 2,
            name: '2'
          },
          {
            value: 3,
            name: '3'
          },
          {
            value: 4,
            name: '4'
          },
        ],
        oldDisciplines: [],
        frontDisciplines: [],
        loadingDisciplines: false,
        loadingDisciplinesUpdate: false,
      }
    },
    computed: {
      ...mapState('docOrdersStudents', ['docOrdersStudents_form']),
    },
    watch: {
      studyCourse() {
        this.getEducationPrograms()
      },
      studyLevelId() {
        this.getEducationPrograms()
      },
      async educationProgramId(id) {
        this.loadingGroup = true
        await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_ID(id)
        this.groupId = 0
        this.loadingGroup = false
      },
      async groupId(id) {
        await this.getDisciplines(id)
      }
    },
    methods: {
      ...mapActions('docOrdersStudents', ['GET_STUDY_LEVELS', 'GET_EDUCATION_PROGRAMS', 'GET_STUDENTS_BY_EDUCATION_PROGRAM_ID']),
      async getEducationPrograms() {
        if (this.studyLevelId && this.studyCourse) {
          await this.GET_EDUCATION_PROGRAMS({studyLevelId: this.studyLevelId, studyCourse: this.studyCourse})
        }
      },
      async getDisciplines(groupId) {
        this.loadingDisciplines = true

        this.oldDisciplines = {}
        this.frontDisciplines = {}

        try {
          const {status, data} = await httpClient.get(`student/student-ratings/get-old-new-disciplines?group_id=${groupId}`)
          if (status === 200) {
            this.oldDisciplines = data.old
            this.frontDisciplines = data.front
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.loadingDisciplines = false
      },
      async updateOldDiscipline(discipline) {
        this.loadingDisciplinesUpdate = true
        try {
          const {status, data} = await httpClient.post(`student/student-ratings/update-old-moodle-credit`, {
            semester: discipline.semester,
            discipline_name: discipline.discipline_name,
            group_id: discipline.group_id,
            credit: discipline.credit,
          })
          if (status === 200 && data) {
            let findDisciplineIndex = -1
            let findSemester = 0
            for (let sem in this.oldDisciplines) {
              findDisciplineIndex = this.oldDisciplines[sem].findIndex(d => d.discipline_name == discipline.discipline_name && d.credit == discipline.credit && d.semester == discipline.semester)
              if (findDisciplineIndex !== -1) {
                findSemester = sem
                break
              }
            }

            if (findDisciplineIndex !== -1) {
              this.oldDisciplines[findSemester][findDisciplineIndex] = data
            }

            this.$message({text: `Данные успешно сохранились`})
          } else {
            this.$error({text: `Данные не найдены`})
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.loadingDisciplinesUpdate = false

      },

      async updateOldDisciplineName(discipline) {

        this.loadingDisciplinesUpdate = true
        try {
          const {status, data} = await httpClient.post(`student/student-ratings/update-old-discipline-name`, {
            semester: discipline.semester,
            discipline_name: discipline.discipline_name,
            discipline_name_ru: discipline.discipline_name_ru,
            discipline_name_kz: discipline.discipline_name_kz,
            discipline_name_en: discipline.discipline_name_en,
            group_id: discipline.group_id
          })
          if (status === 200 && data) {
            let findDisciplineIndex = -1
            let findSemester = 0
            for (let sem in this.oldDisciplines) {
              findDisciplineIndex = this.oldDisciplines[sem].findIndex(d => d.discipline_name == discipline.discipline_name && d.credit == discipline.credit && d.semester == discipline.semester)
              if (findDisciplineIndex !== -1) {
                findSemester = sem
                break
              }
            }

            if (findDisciplineIndex !== -1) {
              this.oldDisciplines[findSemester][findDisciplineIndex] = data
            }

            this.$message({text: `Данные успешно сохранились`})
          } else {
            this.$error({text: `Данные не найдены`})
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.loadingDisciplinesUpdate = false

      },

      async updateFrontDiscipline(discipline) {
        this.loadingDisciplinesUpdate = true

        try {
          const {status, data} = await httpClient.post(`student/student-ratings/update-front-credit`, {
            course_id: discipline.course_id,
            group_id: discipline.group_id,
            credit: discipline.credit,
          })
          if (status === 200 && data) {
            let findDisciplineIndex = -1
            let findSemester = 0
            for (let sem in this.frontDisciplines) {
              findDisciplineIndex = this.frontDisciplines[sem].findIndex(d => d.course_id == discipline.course_id && d.credit == discipline.credit)
              if (findDisciplineIndex !== -1) {
                findSemester = sem
                break
              }
            }

            if (findDisciplineIndex !== -1) {
              this.frontDisciplines[findSemester][findDisciplineIndex] = data
            }

            this.$message({text: `Данные успешно сохранились`})
          } else {
            this.$error({text: `Данные не найдены`})
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.loadingDisciplinesUpdate = false

      },
      clear() {
        this.studyLevelId = 0
        this.studyCourse = 0
        this.educationProgramId = 0
        this.groupId = 0
      }
    },
    async mounted() {
      await this.GET_STUDY_LEVELS()
    }
  }
</script>

<style scoped>


</style>